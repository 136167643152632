import React, { useEffect } from 'react'
import { Link as RouterLink } from 'gatsby'
import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { useLocation } from 'context/location'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Heading,
  Text,
  Link,
  Space,
} from 'components'

import RichTextRenderer from 'components/RichTextRenderer'
import Layout from 'components/Layout'

import Author from '../components/Author'
import RelatedArticles from '../components/RelatedArticles'
import Sidebar from '../components/Sidebar'
import BackToContentFooter from '../components/BackToContentFooter'

const Article = ({ data }) => {
  const { article } = data

  const {
    title,
    seoTitle,
    seoDescription,
    image,
    countries,
    equivalentArticleDE,
    equivalentArticleAT,
    date,
    audience: { name: audience },
    tags,
    body: {
      json: body,
      fields: {
        readingTime: { minutes: readingTime },
      },
    },
    author,
    relatedArticles,
  } = article

  const { countryCode } = useLocation()

  const countryCodeToUse =
    countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode

  useEffect(() => {
    // if we don't have a countries array, do nothing
    if (!Array.isArray(countries)) {
      return
    }

    // if this articles should be shown in the selected country, do nothing
    if (countries.includes(countryCodeToUse.toLowerCase())) {
      return
    }

    // if AT was chosen and we have an AT equivalent of this article, navigate there
    if (
      countryCodeToUse === 'AT' &&
      equivalentArticleAT &&
      equivalentArticleAT.slug
    ) {
      navigate(`/ratgeber/${equivalentArticleAT.slug}`, {
        replace: true,
      })
    }

    // if DE was chosen and we have an DE equivalent of this article, navigate there
    if (
      countryCodeToUse === 'DE' &&
      equivalentArticleDE &&
      equivalentArticleDE.slug
    ) {
      navigate(`/ratgeber/${equivalentArticleDE.slug}`, {
        replace: true,
      })
    }
  }, [countryCodeToUse, countries, equivalentArticleDE, equivalentArticleAT])

  return (
    <Layout
      seo={{
        title: seoTitle || title,
        description: (seoDescription && seoDescription.seoDescription) || '',
        type: 'article',
        image: image.fluid.src,
      }}
    >
      <Section>
        <Container>
          <Row flexWrap="wrap">
            <Column size={[1, 1, 7 / 12, 8 / 12]}>
              <Link
                as={RouterLink}
                to="/ratgeber"
                sx={{
                  display: 'block',
                  fontSize: 0,
                  color: 'colorBlue50',
                  textDecoration: 'none',
                  py: 2,
                  transform: 'translateY(-10px)',
                  mt: -5,
                }}
              >
                <Box
                  as="svg"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 12,
                    height: 9,
                    mr: 2,
                  }}
                >
                  <path
                    d="M11.25 4.49984C11.25 4.84984 11.0167 5.08317 10.6667 5.08317H2.73334L5.24167 7.59151C5.475 7.82484 5.475 8.17484 5.24167 8.40817C5.125 8.52484 5.00834 8.58317 4.83334 8.58317C4.65834 8.58317 4.54167 8.52484 4.425 8.40817L0.925004 4.90817C0.866671 4.84984 0.808337 4.7915 0.808337 4.73317C0.750004 4.6165 0.750004 4.4415 0.808337 4.2665C0.866671 4.20817 0.866671 4.14984 0.925004 4.0915L4.425 0.591504C4.65834 0.358171 5.00834 0.358171 5.24167 0.591504C5.475 0.824837 5.475 1.17484 5.24167 1.40817L2.73334 3.9165H10.6667C11.0167 3.9165 11.25 4.14984 11.25 4.49984Z"
                    fill="#8093AB"
                  />
                </Box>
                zurück zum Ratgeber
              </Link>

              <Box sx={{ position: 'relative' }}>
                {audience && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      transform: 'translate(-5px, -50%)',
                      zIndex: 10,
                    }}
                  >
                    <Box variant="badges.fill">{audience}</Box>
                  </Box>
                )}

                <Box
                  sx={{
                    borderRadius: 'large',
                    overflow: 'hidden',
                    boxShadow: 'sm',
                  }}
                >
                  <Img alt={title} fluid={image.fluid} />
                </Box>
              </Box>

              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', py: 3 }}
              >
                <Box>
                  <Space mr={2} mb={2}>
                    {tags.map(({ name, id }) => (
                      <Box key={id} variant="badges.outline">
                        {name}
                      </Box>
                    ))}
                  </Space>
                </Box>

                <Text variant="muted" mb={2}>
                  {date}
                </Text>
              </Box>

              <Text
                variant="muted"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 0,
                  fontWeight: 'bold',
                  flexGrow: 1,
                  pb: 3,
                }}
              >
                <Box
                  as="svg"
                  viewBox="0 0 11 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{ mr: 2, width: 11, height: 12 }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 0.5C2.45 0.5 0 2.95 0 6C0 9.05 2.45 11.5 5.5 11.5C8.55 11.5 11 9.05 11 6C11 2.95 8.55 0.5 5.5 0.5ZM5.5 10.5C3 10.5 1 8.5 1 6C1 3.5 3 1.5 5.5 1.5C8 1.5 10 3.5 10 6C10 8.5 8 10.5 5.5 10.5ZM7.35 7.85C7.55 7.65 7.55 7.35 7.35 7.15L6 5.8V3C6 2.7 5.8 2.5 5.5 2.5C5.2 2.5 5 2.7 5 3V6C5 6.15 5.05 6.25 5.15 6.35L6.65 7.85C6.75 7.95 6.85 8 7 8C7.15 8 7.25 7.95 7.35 7.85Z"
                    fill="currentColor"
                  />
                </Box>
                Lesezeit {readingTime} Minuten
              </Text>

              <Heading.H2
                as="h1"
                fontWeight="bold"
                sx={{
                  '@media (max-width: 320px)': {
                    fontSize: 30,
                  },
                }}
              >
                {title}
              </Heading.H2>

              <Box sx={{ pt: 3 }}>
                <RichTextRenderer>{body}</RichTextRenderer>
              </Box>
              <Box sx={{ py: 8 }}>
                {author ? (
                  <Author
                    author={author}
                    isCompact={!audience.match(/expert/i)}
                  />
                ) : (
                  <BackToContentFooter />
                )}
              </Box>
            </Column>
            <Column
              id="StickySidebarBottomBoundary"
              size={[1, 1, 5 / 12, 4 / 12]}
            >
              <Sidebar bottomBoundarySelector="#StickySidebarBottomBoundary" />
            </Column>
          </Row>
        </Container>
      </Section>

      <Section sx={{ bg: 'colorBlue5' }}>
        <Container>
          <Row flexWrap="wrap">
            <Column size={[1, 1, 8 / 12, null]}>
              <RelatedArticles articles={relatedArticles} />
            </Column>
            <Column size={[1, 1, 4 / 12, null]}></Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default Article

export const pageQuery = graphql`
  query ($articleId: String) {
    article: contentfulArticle(id: { eq: $articleId }) {
      ...Article
      author {
        ...Author
      }
      relatedArticles {
        ...Article
      }
    }
  }
`
